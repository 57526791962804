body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./link.jpg');
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  cursor: pointer;
  display: block;
  width: 350px;
  padding: 10px;
  margin: auto;
  margin-bottom: 15px;
  color: white;
  border: none;
  border-radius: 3px;
}
img {
  max-width: 500px;
  max-height: 500px;
  margin: auto;
  margin-top: 50px;
  display: block;
  box-shadow: 0px 0px 10px black;
}
input {
  box-sizing: border-box;
  display: block;
  padding: 10px;
  width: 350px;
  margin: auto;
  margin-bottom: 15px;
}
h1 {
  text-align: center;
  font-size: 50px;
  text-shadow: 0px 0px 5px black;
}